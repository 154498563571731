import { Routes } from '@angular/router';
import { AuthGuard } from '@core/auth';

import {
  AUTH_MEANS_URL_SEGMENT,
  CHARGING_SESSIONS_URL_SEGMENT,
  DASHBOARD_URL_SEGMENT,
  EMSP_URL_SEGMENT,
  EULA_URL_SEGMENT,
  MAINTENANCE_URL_SEGMENT,
  PAGE_NOT_FOUND_URL_SEGMENT,
  UNAUTHORIZED_URL_SEGMENT,
} from './core/const/navigation';
import { eulaGuard } from './core/guard/eula.guard';
import {
  AUTH_TOKEN_RESOLVE_PROPERTY,
  USER_RESOLVE_PROPERTY,
  authTokenResolver,
  currentUserResolver,
} from './core/resolvers';
import {
  CONTRACTS_RESOLVE_PROPERTY,
  USER_LAT_LNG_RESOVE_PROPERTY,
  dashboardPageContractsResolver,
  dashboardPageResolver,
} from './features/dashboard/pages/dashboard-page';

export const APP_GUARDS = [AuthGuard, eulaGuard];

export const AppRoutes: Routes = [
  {
    path: DASHBOARD_URL_SEGMENT,
    loadComponent: () => {
      return import('./features/dashboard/pages/dashboard-page');
    },
    canActivate: APP_GUARDS,
    resolve: {
      [USER_RESOLVE_PROPERTY]: currentUserResolver,
      [USER_LAT_LNG_RESOVE_PROPERTY]: dashboardPageResolver,
      [AUTH_TOKEN_RESOLVE_PROPERTY]: authTokenResolver,
      [CONTRACTS_RESOLVE_PROPERTY]: dashboardPageContractsResolver,
    },
  },
  {
    path: MAINTENANCE_URL_SEGMENT,
    loadChildren: async () => {
      return (await import('./features/errors/pages/maintenance-page'))
        .maintenancePageRoutes;
    },
  },
  {
    path: UNAUTHORIZED_URL_SEGMENT,
    loadChildren: async () => {
      return (await import('./features/errors/pages/unauthorized-page'))
        .unauthorizedPageRoutes;
    },
  },
  {
    path: PAGE_NOT_FOUND_URL_SEGMENT,
    loadChildren: async () => {
      return (await import('./features/errors/pages/page-not-found'))
        .pageNotFoundRoutes;
    },
    canActivate: APP_GUARDS,
  },
  {
    path: CHARGING_SESSIONS_URL_SEGMENT,
    loadChildren: async () => {
      return (
        await import('./features/charging-sessions/charging-sessions.routes')
      ).ChargingSessionsRoutes;
    },
    canActivate: APP_GUARDS,
  },
  {
    path: AUTH_MEANS_URL_SEGMENT,
    loadChildren: async () => {
      return (await import('./features/auth-means/auth-means.routes'))
        .authMeansRoutes;
    },
    canActivate: APP_GUARDS,
  },
  {
    path: EMSP_URL_SEGMENT,
    loadChildren: async () => {
      return (await import('./features/emsp/emsp.routes')).EmspRoutes;
    },
  },
  {
    path: EULA_URL_SEGMENT,
    canActivate: [AuthGuard],
    loadChildren: async () => {
      return (await import('./features/terms/terms.routes')).TermsRoutes;
    },
  },
  {
    path: '',
    redirectTo: DASHBOARD_URL_SEGMENT,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: PAGE_NOT_FOUND_URL_SEGMENT,
  },
];
