import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filterNil } from '@core/rxjs/filter-nil';
import { User } from '@model/evd/users';
import { CurrentUserService } from '@store/evd/current-user';
import { map } from 'rxjs';

import { NavigationService } from '../services';

export const eulaGuard: CanActivateFn = (_) => {
  const currentUserService = inject(CurrentUserService);
  const navigationService = inject(NavigationService);

  return currentUserService.currentUserFetched$.pipe(
    filterNil(),
    map((user: User) => {
      if (user.driverEulaAccepted === false) {
        navigationService.navigateToEulaAccept();
        return false;
      }

      return true;
    }),
  );
};
